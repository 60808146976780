import { FC } from "react";
import Container from "../Container";
import Header from "../Header";

type LayoutProps = {
  isApp?: boolean;
};

const Layout: FC<LayoutProps> = ({ children, isApp }) => {
  if (isApp) {
    return <>{children}</>;
  }

  return (
    <>
      <Container>
        <Header />
      </Container>
      <main>{children}</main>
    </>
  );
};

export default Layout;
